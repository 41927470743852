import {LOCALE_ID, NgModule} from '@angular/core';
import localeRu from '@angular/common/locales/ru';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MenuModule} from './menu/menu.module';
import {QuillModule} from 'ngx-quill';
import {registerLocaleData} from '@angular/common';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {AuthInterceptor} from './_interceptors/auth-interceptor';
import {ErrorInterceptor} from './_interceptors/error-interceptor';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MultilineSnackbar} from './snackbar/snackbar.component';
import {getPaginator} from './utils';

registerLocaleData(localeRu);

@NgModule({
  declarations: [
    AppComponent,
    MultilineSnackbar
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    QuillModule.forRoot(),
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'ru'},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: MatPaginatorIntl, useValue: getPaginator()},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
