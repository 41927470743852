import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
  selector: 'app-multiline-snackbar',
  templateUrl: 'snackbar.component.html',
})

export class MultilineSnackbar {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string[]) {}
}
