<div class="flex items-center justify-between menu content">
  <div class="menu--wrap">
    <a *ngIf="user?.access?.documentation" routerLink="/documents" routerLinkActive="menu--item_active" class="menu--item">Документы</a>
    <a *ngIf="user?.access?.support" routerLink="/feedback" routerLinkActive="menu--item_active" class="menu--item">Обратная связь</a>
    <a *ngIf="user?.access?.notifications" routerLink="/quiz" routerLinkActive="menu--item_active" class="menu--item">Опросы</a>
    <a *ngIf="user?.access?.notifications" routerLink="/notifications" routerLinkActive="menu--item_active" class="menu--item">Уведомления</a>
    <a *ngIf="user?.access?.faq" routerLink="/faq" routerLinkActive="menu--item_active" class="menu--item">Часто задаваемые вопросы</a>
    <a *ngIf="user?.access?.adminUser" routerLink="/users" routerLinkActive="menu--item_active" class="menu--item">Пользователи</a>
    <a *ngIf="user?.access?.banner" routerLink="/banners" routerLinkActive="menu--item_active" class="menu--item">Баннеры</a>
    <a *ngIf="user?.access?.settings" routerLink="/setting" routerLinkActive="menu--item_active" class="menu--item">Настройки</a>
    <a *ngIf="user?.access?.article" routerLink="/articles" routerLinkActive="menu--item_active" class="menu--item">Статьи</a>
    <a *ngIf="user?.access?.report" routerLink="/reports" routerLinkActive="menu--item_active" class="menu--item">Отчеты</a>
  </div>
  <div class="flex items-center">
    <div class="menu--icon">
      <mat-icon>person</mat-icon>
    </div>
    <p class="menu--name">{{user?.email}}</p>
    <p class="pl-2 pr-2">|</p>
    <p class="menu--name" (click)="logout()">Выйти</p>
  </div>
</div>
