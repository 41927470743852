import { Component } from '@angular/core';
import {UserService} from "../../user/user.service";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  get user() {
    return this.userService.user;
  }

  constructor(private userService: UserService) {}

  logout() {
    this.userService.logout();
  }
}
