import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {UserService} from "../user/user.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor( private service: UserService) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!request.url.includes('login')) {

       request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.service.user?.token}`,
        },
      });
    }

    return next.handle(request);
  }
}
