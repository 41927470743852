import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import {UserLogin} from './types/user.model';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private loginUrl = environment.apiPrefix + 'admin/login/';

  private key = 'user';
  user$ = new BehaviorSubject<UserType | null>(null)
  isClubAdmin = false;
  isErrorLoginOrPassword = false;

  set user(user) {
    user && this.user$.next(user);
    localStorage.setItem(this.key, JSON.stringify(user));
  }

  get user(): UserType | null {
    let user: UserType | null = null;
    const data = localStorage.getItem(this.key);
    if (data) {
      user = JSON.parse(data);
      this.user$.next(user);
    }
    return user;
  }

  constructor(private http: HttpClient, private router: Router) {}

  login(data: UserLogin): Observable<any> {
    return this.http.post(this.loginUrl, data)
      .pipe(
        tap((res: any) => {
          this.user = res;
        })
      )
  }

  isAdmin(roles: string[]): boolean {
    return roles.includes('CLUBADMIN');
  }

  logout():void {
    void this.router.navigate(['/login']);
    this.deleteUserData();
  }

  deleteUserData(): void {
    localStorage.removeItem(this.key);
  }

}
